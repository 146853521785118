const config = {
  rootProjectId: "root",
  uiBucketName: "sbuxmiddleware.c.retter.io",
  appUrl: "https://api.sbuxmiddleware.retter.io/",
  cosUrl: "api.sbuxmiddleware.retter.io",
  version: "2.1.5",
  captchaKey: "6Ldt9CkqAAAAAFgKC1cnn-AavwpD0YQv1Fm12QUo",
  stage: "PROD"
}
export default config

